/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 18번째 에러 로그에서는 연말 정산, 스타트렉: 디스커버리, 오티스의 비밀 상담소, 더 이상 숨을 곳이 없다 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.nts.go.kr/support/support_03.asp?cinfo_key=MINF7820100716140953"
  }, "국세청-성실신고지원>원천징수(연말정산)안내")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.hani.co.kr/arti/economy/economy_general/694351.html"
  }, "원천징수 세액 납세자가 정한다 : 경제일반 : 경제 : 뉴스 : 한겨레")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.nts.go.kr/support/support_03_etc01.asp"
  }, "국세청-성실신고지원>연말정산>근로소득 간이세액표")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.nts.go.kr/news/news_05.asp?type=V&minfoKey=MINF5320080211205338&mbsinfoKey=MBS20181128143154017"
  }, "국세청-국세청뉴스>공지사항")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://likms.assembly.go.kr/bill/main.do#"
  }, "의안정보시스템")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://seoul.co.kr/news/newsView.php?id=20180227008009"
  }, "서울신문 - 청년 나이 기준 35~45세 제각각… 정당들 말뿐인 ‘청년 정치’")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.netflix.com/kr/title/80197526"
  }, "오티스의 비밀 상담소 | Netflix 공식 사이트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%8A%A4%ED%83%80%EC%9B%8C%EC%A6%88"
  }, "스타워즈 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.netflix.com/kr/title/80126024"
  }, "스타트렉: 디스커버리 | Netflix 공식 사이트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%A6%AC%EB%B6%80%ED%8A%B8_%28%EC%9E%91%ED%92%88%29"
  }, "리부트 작품 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%B9%85%EB%B1%85_%EC%9D%B4%EB%A1%A0_%28%EC%8B%9C%ED%8A%B8%EC%BD%A4%29"
  }, "빅뱅 이론 (시트콤) - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%9D%80%ED%95%98%EC%98%81%EC%9B%85%EC%A0%84%EC%84%A4"
  }, "은하영웅전설 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%8B%A4%EB%82%98%EC%B9%B4_%EC%9A%94%EC%8B%9C%ED%82%A4"
  }, "다나카 요시키 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%95%84%EB%8B%A4%EC%B9%98_%EB%AF%B8%EC%B8%A0%EB%A3%A8"
  }, "아다치 미츠루 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www-cs-faculty.stanford.edu/~knuth/taocp.html"
  }, "The Art of Computer Programming")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=140702246"
  }, "알라딘: Concrete Mathematics 구체 수학 - 컴퓨터과학의 기초를 다지는 단단한 수학")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EA%B8%80%EB%A0%8C_%EA%B7%B8%EB%A6%B0%EC%9B%94%EB%93%9C"
  }, "글렌 그린월드 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/wproduct.aspx?ItemId=40676668"
  }, "알라딘: 더 이상 숨을 곳이 없다 - 스노든, NSA, 그리고 감시국가")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EC%97%90%EB%93%9C%EC%9B%8C%EB%93%9C_%EC%8A%A4%EB%85%B8%EB%93%A0"
  }, "에드워드 스노든 - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/1984%EB%85%84_%28%EC%86%8C%EC%84%A4%29"
  }, "1984년 (소설) - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/PGP_%28%EC%86%8C%ED%94%84%ED%8A%B8%EC%9B%A8%EC%96%B4%29"
  }, "PGP (소프트웨어) - 위키백과, 우리 모두의 백과사전")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://zariski.wordpress.com/2014/10/10/%EC%99%9C-%EC%A0%95%EB%B6%80-%EA%B0%90%EC%8B%9C%EB%A5%BC-%EB%A7%89%EC%95%84%EC%95%BC-%ED%95%98%EA%B3%A0-%ED%94%84%EB%9D%BC%EC%9D%B4%EB%B2%84%EC%8B%9C%EA%B0%80-%EC%A4%91%EC%9A%94%ED%95%9C%EA%B0%80/"
  }, "왜 정부 감시를 막아야 하고, 프라이버시가 중요한가? | 내 백과사전")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
